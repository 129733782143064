import React, { useState, useEffect } from 'react';
import { getMessaging, onMessage } from 'firebase/messaging';
import { app, isStandalone } from '@/utils/utils';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { getConversationsDashboard } from '@/app/services/messages/messages';
import history from '@/@history';
import DashboardCard from '@/app/fuse-layouts/layout1/components/Dashboard/DashboardCard';
import { useDispatch } from 'react-redux';
import * as ChatActions from '@/app/main/subadmin/messaging/messaging/ChatThread/store/actions';

const useStyles = makeStyles((theme) => ({
	contactListItem: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		'&.active': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	unreadBadge: {
		background: theme.palette.background.blue,
		color: theme.palette.secondary.contrastText,
	},
}));

const messaging = getMessaging(app);

function MessageListing(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [conversations, setConversations] = useState([]);
	const [page, setPage] = useState(1);
	const dispatch = useDispatch();

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible' && isStandalone()) {
				// Refetch notifications when pwa comes back into focus
				getConversationsDashboard(true)
					.then((res) => {
						setConversations(res.data.data);
						if (page < res.data.last_page) {
							setPage(page + 1);
						}
					})
					.catch((err) => console.log(err));
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	onMessage(messaging, (payload) => {
		if (payload?.data?.click_action === 'chat_push_notification') {
			const { data } = payload;
			let { message_data: messageData } = data;
			messageData = JSON.parse(messageData);
			const newContact = {
				created_at: messageData.created_at,
				id: messageData.conversation_id,
				messages_unread_count: data.unread_count,
				meta: messageData.meta,
				student_id: Number(data.student_id),
				student: {
					photo: data.student_photo,
					first_name: data.student_name?.split(' ')[0],
					last_name: data.student_name?.split(' ')[1],
					student_id: Number(data.student_id),
				},
				school: {},
				parent_last_message: {
					attachement: messageData.attachement,
					conversation_id: messageData.conversation_id,
					message: messageData.message,
					message_type: messageData.message_type,
					message_from_role: messageData.message_from_role,
					meta: messageData.meta,
				},
				last_message: {
					attachement: messageData.attachement,
					conversation_id: messageData.conversation_id,
					message: messageData.message,
					message_type: messageData.message_type,
					message_from_role: messageData.message_from_role,
					meta: messageData.meta,
				},
			};
			const temp = [newContact];
			temp.push(...conversations.filter((contact) => Number(contact.id) !== Number(messageData.conversation_id)));
			setConversations(temp);
		}
	});

	const handleBackgroundChatMessage = (payload) => {
		if (document.visibilityState === 'hidden' && payload.data.click_action === 'chat_push_notification') {
			const { data } = payload.data;
			let { message_data: messageData } = data;
			messageData = JSON.parse(messageData);
			const newContact = {
				created_at: messageData.created_at,
				id: messageData.conversation_id,
				messages_unread_count: data.unread_count,
				meta: messageData.meta,
				student_id: Number(data.student_id),
				student: {
					photo: data.student_photo,
					first_name: data.student_name?.split(' ')[0],
					last_name: data.student_name?.split(' ')[1],
					student_id: Number(data.student_id),
				},
				school: {},
				parent_last_message: {
					attachement: messageData.attachement,
					conversation_id: messageData.conversation_id,
					message: messageData.message,
					message_type: messageData.message_type,
					message_from_role: messageData.message_from_role,
					meta: messageData.meta,
				},
				last_message: {
					attachement: messageData.attachement,
					conversation_id: messageData.conversation_id,
					message: messageData.message,
					message_type: messageData.message_type,
					message_from_role: messageData.message_from_role,
					meta: messageData.meta,
				},
			};
			setConversations((oldConvers) => {
				const temp = [newContact];
				temp.push(
					...oldConvers.filter((contact) => Number(contact.id) !== Number(messageData.conversation_id))
				);
				return temp;
			});
		}
	};

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		getConversationsDashboard()
			.then((res) => {
				if (!isMounted) return;
				setConversations(res.data.data);
				if (page < res.data.last_page) {
					setPage(page + 1);
				}
			})
			.catch((err) => {
				if (!isMounted) return;
				console.log(err);
			})
			.finally(() => {
				if (!isMounted) return;
				setIsLoading(false);
			});
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		navigator.serviceWorker.addEventListener('message', handleBackgroundChatMessage);
		return () => {
			navigator.serviceWorker.removeEventListener('message', handleBackgroundChatMessage);
		};
	}, []);

	const openChat = (id) => {
		dispatch(ChatActions.setselectedContactId(id));
		const selectedConversation = conversations.filter((contact) => contact.id === id)[0];
		const temp = conversations.map((conversation) => {
			if (conversation.id === selectedConversation.id) {
				conversation.messages_unread_count = 0;
			}
			return conversation;
		});
		dispatch(ChatActions.updateContacts(temp));
		history.push({
			pathname: '/messaging-chat',
		});
	};

	return (
		<DashboardCard
			showViewMore={conversations?.length > 5}
			onViewMore={() => history.push('/messaging')}
			title="Messaging"
		>
			<div
				style={{
					background: '#fff',
					minHeight: '422px',
				}}
				className="flex relative flex-col gap-20"
			>
				{isLoading ? (
					<div className="flex justify-center absolute inset-0 items-center mt-16">
						<CircularProgress size={35} />
					</div>
				) : (
					''
				)}
				<div className="truncate">
					{conversations?.length ? (
						conversations?.slice(0, 5)?.map((conversation, index) => {
							const isLast = index === 4;
							return (
								<MessageListingItem
									conversation={conversation}
									isLast={isLast}
									openChat={openChat}
									key={conversation.id}
								/>
							);
						})
					) : isLoading ? (
						''
					) : (
						<div className="flex justify-center pt-32">No message received</div>
					)}
				</div>
			</div>
		</DashboardCard>
	);
}

export default MessageListing;

function MessageListingItem({ conversation, isLast, openChat }) {
	const classes = useStyles();

	const handleOpenChat = () => {
		openChat(conversation?.id);
	};

	return (
		<div
			className={`flex justify-between py-20 cursor-pointer hover:bg-grey-50 ${!isLast && 'border-b'}`}
			onClick={handleOpenChat}
			style={{ transition: 'background-color 0.3s ease' }}
		>
			<div className="flex gap-10" style={{ width: '80%' }}>
				<div className="relative">
					<Avatar
						style={{ width: 45, height: 45 }}
						src={conversation.student.thumb || conversation.student.photo}
					/>
					{conversation.messages_unread_count > 0 && (
						<span className="absolute" style={{ top: 30, left: 30 }}>
							<div
								className={clsx(
									classes.unreadBadge,
									'flex items-center justify-center w-20 h-20 rounded-full text-12 text-white text-center font-bold'
								)}
							>
								{conversation.messages_unread_count}
							</div>
						</span>
					)}
				</div>
				<div className="flex flex-col overflow-y-auto">
					<Typography className="font-bold truncate">
						{`${conversation.student.first_name} ${conversation.student.last_name}`}
					</Typography>
					<Typography noWrap>
						{conversation?.parent_last_message
							? conversation?.parent_last_message?.message_type === 'file'
								? 'Parent sent an attachment'
								: conversation?.parent_last_message?.message
							: conversation?.last_message === 'file'
							? 'Parent sent an attachment'
							: conversation?.last_message?.message}
					</Typography>
				</div>
			</div>
			<div>
				<Typography className="text-xs" color="textSecondary" noWrap>
					{moment(conversation.updated_at).format('LT')}
				</Typography>
			</div>
		</div>
	);
}
